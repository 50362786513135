import container from './container.vue';

export const RouteDomains = {
  path: '/domains',
  name: 'Domains',
  component: container,
  redirect: {
    name: 'domainsMain',
  },
  meta: {
    isFlag: true,
    isShow: true,
    isLink: true,
    name: 'Домены и DNS',
    navigation: {
      service: {
        key: 'domains',
        icon: 'dns',
        order: 6,
      },
    },
    access: ['mainmenuservice.domain', 'mainmenuservice.dnshost'],
  },
  children: [
    {
      path: '',
      name: 'domainsMain',
      meta: {
        isFlag: true,
        isShow: true,
        isLink: false,
        name: 'Домены и DNS',
      },
      component: () => import(/* webpackChunkName: "domains-main" */ './pages/Main/container.vue'),
      redirect: {
        name: 'domainsList',
      },
      children: [
        {
          path: '',
          name: 'domainsList',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Список доменов и поддоменов',
          },
          component: () =>
            import(/* webpackChunkName: "domains-list" */ './pages/Main/views/List.vue'),
        },
        {
          path: 'order',
          name: 'domainsOrder',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Зарегистрировать домен',
          },

          component: () =>
            import(/* webpackChunkName: "domains-order" */ './pages/Main/views/Order.vue'),
        },
        {
          path: 'contact',
          name: 'domainsContact',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Контакты доменов',
          },
          component: () =>
            import(/* webpackChunkName: "domains-contact" */ './pages/Main/views/Contact.vue'),
          // props: true,
          // children: [,
        },
      ],
    },
    {
      path: ':id',
      // path: 'domain/:id',
      name: 'domainDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      redirect: { name: 'domainInfo' },
      component: () =>
        import(/* webpackChunkName: "domains-detail" */ './pages/Detail/DomainDetail.vue'),
      props: true,
      children: [
        {
          path: 'info',
          name: 'domainInfo',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "domains-detail-info" */ './pages/Detail/views/Info.vue'),
        },
        {
          path: 'whois',
          name: 'domainWhois',
          meta: {
            name: 'Whois',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "domains-detail-whois" */ './pages/Detail/views/Whois.vue'),
        },
        {
          path: 'ns',
          name: 'domainNs',
          meta: {
            name: 'Сервера имен (NS)',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "domains-detail-ns" */ './pages/Detail/views/Ns.vue'),
        },
        {
          path: 'dns',
          name: 'domainDns',
          component: () =>
            import(/* webpackChunkName: "domains-detail-dns" */ './pages/Detail/views/Dns.vue'),
        },
        {
          path: 'history',
          name: 'domainHistory',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },

          component: () =>
            import(
              /* webpackChunkName: "domains-detail-history" */ './pages/Detail/views/History.vue'
            ),
        },
      ],
    },
    {
      path: 'dnshost/:id/',
      name: 'dnsServiceDetail',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Управление DNS-услугой',
      },
      redirect: { name: 'container' },
      component: () =>
        import(/* webpackChunkName: "dnsService-detail" */ './pages/Detail/DnsServiceDetail.vue'),
      props: true,
      children: [
        {
          path: 'dnsService',
          name: 'container',
          meta: {
            isShow: true,
            isLink: false,
          },
          redirect: { name: 'dnsServiceInfo' },
          component: () =>
            import(
              /* webpackChunkName: "dnsService-detail-info" */ './pages/Detail/views/container.vue'
            ),
          children: [
            {
              path: 'info',
              name: 'dnsServiceInfo',
              meta: {
                name: 'DNS-записи',
                isShow: true,
                isLink: false,
              },
              component: () =>
                import(
                  /* webpackChunkName: "dnsService-detail-info" */ './pages/Detail/views/DnsInfo.vue'
                ),
            },
            {
              path: 'history',
              name: 'dnsServiceHistory',
              meta: {
                name: 'История',
                isShow: true,
                isLink: false,
              },
              component: () =>
                import(
                  /* webpackChunkName: "dnshost-detail-history" */ './pages/Detail/views/History.vue'
                ),
            },
          ],
        },
      ],
    },
    {
      path: ':id/',
      name: 'dnshostDetail',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Управление DNS-зоной',
      },
      redirect: { name: 'dnshostDomain' },
      component: () =>
        import(/* webpackChunkName: "dnshost-detail" */ './pages/Detail/DnsHostDetail.vue'),
      props: true,
      children: [
        {
          path: ':domain',
          name: 'dnshostDomain',
          meta: {
            isShow: true,
            isLink: false,
          },
          // redirect: { name: 'dnshostDns' },
          // component: () =>
          //   import(
          //     /* webpackChunkName: "dnshost-detail-dns" */ './pages/Detail/views/container.vue'
          //   ),
          component: () =>
            import(/* webpackChunkName: "dnshost-detail-dns" */ './pages/Detail/views/Dns.vue'),
          // children: [
          //   {
          //     path: 'dns',
          //     name: 'dnshostDns',
          //     meta: {
          //       name: 'DNS-записи',
          //       isShow: true,
          //       isLink: false,
          //     },
          //     component: () =>
          //       import(/* webpackChunkName: "dnshost-detail-dns" */ './pages/Detail/views/Dns.vue'),
          //   },
          //   {
          //     path: 'info',
          //     name: 'dnshostInfo',
          //     meta: {
          //       isShow: true,
          //       isLink: false,
          //     },
          //     component: () =>
          //       import(
          //         /* webpackChunkName: "dnshost-detail-info" */ './pages/Detail/views/Info.vue'
          //       ),
          //   },
          //   {
          //     path: 'history',
          //     name: 'dnshostHistory',
          //     meta: {
          //       name: 'История',
          //       isShow: true,
          //       isLink: false,
          //     },
          //     component: () =>
          //       import(
          //         /* webpackChunkName: "dnshost-detail-history" */ './pages/Detail/views/History.vue'
          //       ),
          //   },
          // ],
        },
      ],
    },
  ],
};
