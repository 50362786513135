import container from './container.vue';

export const RouteVirtuozzo = {
  path: '/licvz',
  name: 'virtuozzo',
  redirect: { name: 'virtuozzoMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Лицензии Virtuozzo',
    navigation: {
      service: {
        key: 'virtuozzo',
        icon: 'vz',
        order: 8,
      },
    },
    access: ['mainmenuservice.licvz'],
  },
  children: [
    {
      path: '',
      name: 'virtuozzoMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Лицензии Virtuozzo',
      },
      component: () => import(/* webpackChunkName: "virtuozzo-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'virtuozzoOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Покупка лицензии Virtuozzo',
      },
      component: () =>
        import(/* webpackChunkName: "virtuozzo-order" */ './pages/Order/VirtuozzoOrder.vue'),
      children: [
        {
          path: '/licvz/order',
          redirect: { name: 'virtuozzoOrder' },
        },
        {
          path: '/licvz/order/:id/:period',
          name: 'virtuozzoOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "virtuozzo-order-details" */ './pages/Order/VirtuozzoOrderDetails.vue'
            ),
        },
        {
          path: '/licvz/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'virtuozzoOrderDetails' },
        },
        {
          path: '/licvz/order/:id/:period/5', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'virtuozzoOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'virtuozzoDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "virtuozzo-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewVirtuozzo' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Обзор',
          },
          component: () =>
            import(
              /* webpackChunkName: "virtuozzo-info-view" */ './pages/Information/views/View.vue'
            ),
          name: 'viewVirtuozzo',
        },
        {
          path: 'config',
          component: () =>
            import(
              /* webpackChunkName: "virtuozzo-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configVirtuozzo',
        },
        {
          path: 'backup',
          component: () =>
            import(
              /* webpackChunkName: "virtuozzo-info-backup" */ './pages/Information/views/Backup.vue'
            ),
          name: 'backupVirtuozzo',
        },
        {
          path: 'history',
          meta: {
            isShow: true,
            isLink: false,
            name: 'История',
          },
          component: () =>
            import(
              /* webpackChunkName: "virtuozzo-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyVirtuozzo',
        },
      ],
    },
  ],
};
