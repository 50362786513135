import { formDataParser } from '@/utils/parsers/formDataParser';
import BillMgrApi from '@/services/api/BillMgrApi';

export default {
  fetchBillMgrToolAction(_ctx, payload) {
    const params = {
      ...payload,
      newface: 'on',
      show_metadata: 'on',
    };
    if (payload.id) params.elid = payload.id;
    if (payload.newface === 'none') delete params.newface;
    if (payload.show_metadata === 'none') delete params.show_metadata;
    return BillMgrApi.get('/billmgr', { params });
  },
  sendBillMgrToolAction(_ctx, payload) {
    if (!payload.func) throw new Error('Missed required param "func"');
    const params = {
      ...payload,
      newface: 'on',
      out: 'bjson',
    };
    if (!payload.debug && !payload.hasOwnProperty('sok')) params.sok = 'ok';
    if (payload.sok === 'none' || payload.sok === '') delete params.sok;
    if (payload.newface === 'none') delete params.newface;
    if (payload.show_metadata === 'none') delete params.show_metadata;
    if (!payload.auth) params.auth = localStorage.getItem('token');
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  sendBillMgrToolActionLk(_ctx, payload) {
    if (!payload.func) throw new Error('Missed required param "func"');
    const params = {
      ...payload,
      newface: 'on',
      out: 'xjson',
    };
    if (!payload.debug && !payload.hasOwnProperty('sok')) params.sok = 'ok';
    if (payload.sok === 'none' || payload.sok === '') delete params.sok;
    if (payload.newface === 'none') delete params.newface;
    if (payload.show_metadata === 'none') delete params.show_metadata;
    if (!payload.auth) params.auth = localStorage.getItem('token');
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  sendBillMgrToolActionRecover(_ctx, payload) {
    if (!payload.func) throw new Error('Missed required param "func"');
    const params = {
      ...payload,
      // newface: 'on',
      out: 'bjson',
    };
    if (!payload.debug && !payload.hasOwnProperty('sok')) params.sok = 'ok';
    // if (!payload.auth) params.auth = localStorage.getItem('token');
    const formData = formDataParser(params);
    return BillMgrApi.post('/billmgr', formData);
  },
  fetchBillMgrToolActionWithFunction(_ctx, payload) {
    const params = {
      ...payload,
      newface: 'on',
      show_metadata: 'on',
    };
    if (payload.id) params.elid = payload.id;
    return BillMgrApi.get('/api2/billmgr/addoncost', { params });
  },
};
