import container from './container.vue';

export const RouteIdprotect = {
  path: '/idprotect',
  name: 'idprotect',
  redirect: { name: 'idprotectMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Защита Whois',
    navigation: {
      service: {
        key: 'idprotect',
        icon: 'idprotect',
        order: 12,
      },
    },
    access: ['mainmenuservice.idprotect'],
  },
  children: [
    {
      path: '',
      name: 'idprotectMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Защита Whois',
      },
      component: () => import(/* webpackChunkName: "idprotect-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'idprotectOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Заказ защиты данных владельца домена',
      },
      component: () =>
        import(/* webpackChunkName: "idprotect-order" */ './pages/Order/IdprotectOrder.vue'),
      children: [
        {
          path: '/idprotect/order',
          redirect: { name: 'idprotectOrder' },
        },
        {
          path: '/idprotect/order/:id/:period',
          name: 'idprotectOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "idprotect-order-details" */ './pages/Order/IdprotectOrderDetails.vue'
            ),
        },
        {
          path: '/idprotect/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'idprotectOrderDetails' },
        },
        {
          path: '/idprotect/order/:id/:period/5', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'idprotectOrderDetails' },
        },
        {
          path: '/idprotect/order/:id/:period/2', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'idprotectOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'idprotectDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "idprotect-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewIdprotect' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "idprotect-info-view" */ './pages/Information/views/View.vue'
            ),
          name: 'viewIdprotect',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "idprotect-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyIdprotect',
        },
      ],
    },
  ],
};
