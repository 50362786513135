import container from './container.vue';

export const RouteAcronis = {
  path: '/cloudsBackup',
  name: 'acronis',
  redirect: { name: 'acronisMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Облачный бэкап',
    navigation: {
      service: {
        key: 'acronis',
        icon: 'acronis',
        order: 5,
      },
    },
    access: ['mainmenuservice.acronis'],
  },
  children: [
    {
      path: '',
      name: 'acronisMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Облачный бэкап',
      },
      component: () => import(/* webpackChunkName: "acronis-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'acronisOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Заказ облачного бэкапа',
      },
      component: () =>
        import(/* webpackChunkName: "acronis-order" */ './pages/Order/AcronisOrder.vue'),
      children: [
        {
          path: '/cloudsBackup/order',
          redirect: { name: 'acronisOrder' },
        },
        {
          path: '/cloudsBackup/order/:id/:period',
          name: 'acronisOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "acronis-order-details" */ './pages/Order/AcronisOrderDetails.vue'
            ),
        },
        {
          // костыль для project=3  - нужен во всем проекте - для русоникса - исправить на загрузку из стора
          path: '/cloudsBackup/order/:id/:period/3',
          redirect: { name: 'acronisOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'acronisDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "acronis-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewAcronis' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "acronis-info-view" */ './pages/Information/views/View.vue'
            ),
          name: 'viewAcronis',
        },
        {
          path: 'config',
          meta: {
            name: 'Конфигуратор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "acronis-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configAcronis',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "acronis-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyAcronis',
        },
      ],
    },
  ],
};
