import container from './container.vue';

export const RoutePlesk = {
  path: '/licplesk',
  name: 'plesk',
  redirect: { name: 'pleskMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Лицензии Plesk',
    navigation: {
      service: {
        key: 'plesk',
        icon: 'licplesk',
        order: 8,
      },
    },
    access: ['mainmenuservice.licplesk'],
  },
  children: [
    {
      path: '',
      name: 'pleskMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Лицензии Plesk',
      },
      component: () => import(/* webpackChunkName: "plesk-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'pleskOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Покупка лицензии Plesk',
      },
      component: () => import(/* webpackChunkName: "plesk-order" */ './pages/Order/PleskOrder.vue'),
      children: [
        {
          path: '/licplesk/order',
          redirect: { name: 'pleskOrder' },
        },
        {
          path: '/licplesk/order/:id/:period',
          name: 'pleskOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "plesk-order-details" */ './pages/Order/PleskOrderDetails.vue'
            ),
        },
        {
          path: '/licplesk/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'pleskOrderDetails' },
        },
        {
          path: '/licplesk/order/:id/:period/5', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'pleskOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'pleskDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "plesk-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewPlesk' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "plesk-info-view" */ './pages/Information/views/View.vue'),
          name: 'viewPlesk',
        },
        {
          path: 'config',
          meta: {
            name: 'Параметры',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "plesk-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configPlesk',
        },
        {
          path: 'backup',
          meta: {
            name: 'Бэкапы',
            isShow: true,
            isLink: false,
          },

          component: () =>
            import(
              /* webpackChunkName: "plesk-info-backup" */ './pages/Information/views/Backup.vue'
            ),
          name: 'backupPlesk',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "plesk-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyPlesk',
        },
      ],
    },
  ],
};
