import container from './container.vue';

export const RouteVhost = {
  path: '/vhost',
  name: 'vhost',
  redirect: { name: 'vhostMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Виртуальный хостинг ISP',
    navigation: {
      service: {
        key: 'vhost-hosting',
        icon: 'vhost',
        order: 4,
      },
    },
    access: ['mainmenuservice.vhost'],
  },
  children: [
    {
      path: '',
      name: 'vhostMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Виртуальный хостинг ISP',
      },
      component: () => import(/* webpackChunkName: "vhost-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'vhostOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Создание виртуального хостинга ISP',
      },
      component: () => import(/* webpackChunkName: "vhost-order" */ './pages/Order/VhostOrder.vue'),
      children: [
        {
          path: '/vhost/order',
          redirect: { name: 'vhostOrder' },
        },
        {
          path: '/vhost/order/:id/:period',
          name: 'vhostOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "vhost-order-details" */ './pages/Order/VhostOrderDetails.vue'
            ),
        },
        {
          path: '/vhost/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'vhostOrderDetails' },
        },
        {
          path: '/vhost/order/:id/:period/5', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'vhostOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'vhostDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "vhost-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewVhost' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(/* webpackChunkName: "vhost-info-view" */ './pages/Information/views/View.vue'),
          name: 'viewVhost',
        },
        {
          path: 'config',
          meta: {
            name: 'Изменить конфигурацию',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "vhost-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configVhost',
        },
        {
          path: 'backup',
          meta: {
            name: 'Бэкапы',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "vhost-info-backup" */ './pages/Information/views/Backup.vue'
            ),
          name: 'backupVhost',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "vhost-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyVhost',
        },
      ],
    },
  ],
};
