<template>
  <component :is="tag" v-bind="props" class="plain-btn" :class="classes" v-on="$listeners">
    <svg-icon v-if="icon" :name="icon" class="plain-btn__icon" />
    <span v-if="$slots.default || title" class="plain-btn__text">
      <slot>{{ title }}</slot>
    </span>
    <base-loader v-if="loading" class="plain-btn__loader" />
  </component>
</template>

<script>
export default {
  name: 'PlainButton',
  props: {
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'default',
      validator: value =>
        ['default', 'dark', 'light', 'success', 'primary', 'dim', 'del', 'warn'].includes(value),
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => ['minimal', 'small', 'medium', 'big'].includes(value),
    },
    to: {
      type: [String, Object],
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    watchActive: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tag() {
      return this.to ? 'router-link' : this.$attrs.href ? 'a' : 'button';
    },
    props() {
      let obj = { ...this.$attrs };
      if (this.to) obj.to = this.to;
      return obj;
    },
    classes() {
      const base = 'plain-btn';
      return {
        [`${base}--${this.color}`]: this.color,
        [`${base}--${this.size}`]: this.size,
        [`${base}--disabled`]: this.$attrs.disabled,
        [`${base}--loading`]: this.loading,
        [`${base}--active`]: this.watchActive && this.active,
        [`${base}--inactive`]: this.watchActive && !this.active,
        [`${base}--nowrap`]: this.nowrap,
        [`${base}--only-icon`]: this.icon && !this.title && !this.$slots.default,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$pb-colors = {
  default: inherit,
  dark: $color-dark.black,
  light: $color-light.white,
  primary: $primary-color,
  dim: $color-light.medium,
  success: $color-green.base,
  del: $color-red.light,
  warn: #cbb235, //TODO
}
$pb-sizes = {
  minimal: {
  text: $font-size-smallest,
  line: $font-size-smallest,
  icon: $font-size-smallest,
  iconSingle: $font-size-smallest,
},
  small: {
    text: $font-size-small,
    line: $line-height-small,
    icon: $line-height-small,
    iconSingle: $line-height-small,
  },
  medium: {
    text: $font-size-base,
    line: $line-height-base,
    icon: $font-size-medium,
    iconSingle: $font-size-bigger,
  }
  big: {
    text: $font-size-medium,
    line: $line-height-medium,
    icon: $font-size-large,
    iconSingle: $font-size-large,
  }
}
$pb-hovers = {
  dark: lighten($pb-colors.dark, 20%),
  light: darken($pb-colors.light, 20%),
  primary: $color-blue.medium,
  dim: darken($pb-colors.dim, 20%),
  success: darken($pb-colors.success, 20%),
  del: darken($pb-colors.del, 20%),
}
.plain-btn {
  font-family: inherit;
  flexyi(center, center);
  //padding: 0.25rem;
  //margin: -0.25rem;
  background: none;
  border: 0;
  box-shadow: none;
  appearance: none;
  cursor: pointer;
  text-decoration: none;
  //transition: color $anim-base-duration ease;
  transition: inherit;

  &:focus {
    outline: none;
  }
  &--nowrap {
    white-space: nowrap;
  }

  // colors
  for $key, $value in $pb-colors {
    &--{$key} {
      color: $value;
    }
  }
  for $key, $value in $pb-hovers {
    &--{$key}:hover {
      color: $value;
    }
  }

  // sizes
  for $key, $value in $pb-sizes {
    &--{$key} ^[0]__text {
      font-size: $value.text;
      line-height: $value.line;
    }
    &--{$key} ^[0]__icon,
    &--{$key} ^[0]__loader {
      font-size: $value.icon;
    }
    &--{$key}&--only-icon ^[0]__icon {
      font-size: $value.iconSingle;
    }
  }

  &--inactive {
    opacity: 0.2;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.4;
    }
  }
  &--active {
    opacity: 1;
  }

  &__icon {
    display: block;
    line-height: 1;
    pointer-events: none;
    user-select: none;
    transition: opacity 0.1s ease;

    ^[0]--loading & {
      opacity: 0;
    }
  }
  &__text {
    font-weight: $font-weight-medium;
    pointer-events: none;
    //user-select: none;
    user-select: text;
    text-decoration: none;
    transition: opacity 0.1s ease;

    ^[0]--loading & {
      opacity: 0;
    }

    ^[0]__icon + & {
      margin-left: 0.25rem;
    }
  }

  &--disabled,
  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
  }
  &--loading {
    pointer-events: none;
  }

  &__loader {
    position: absolute;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 0.1s ease;

    ^[0]--loading & {
      color: inherit;
      opacity: 1;
    }
  }
}
</style>
