<template>
  <main-card :to="to" class="start-card" :class="classes" v-on="$listeners">
    <div class="start-card__inner">
      <div class="start-card__content">
        <div class="start-card__number">
          <svg-icon v-if="icon" :name="icon" size="icons" :class="icon" />
          <!--          <i v-if="icon" class="sidebar-nav__link-icon" :class="icon" />-->
        </div>
        <div v-if="link" class="start-card__text big-text">
          <a :href="link" target="_blank" class="start-card__text-link">{{ text }}</a>
        </div>
        <div v-else class="start-card__text big-text">
          {{ text }}
        </div>
      </div>
    </div>
  </main-card>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
export default {
  name: 'StartCard',
  components: { MainCard },
  props: {
    number: {
      type: [String, Number],
      default: '0',
    },
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
      validator: val => ['primary', 'error'].includes(val),
    },
    icon: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
  },
  computed: {
    classes() {
      const base = 'start-card';
      return {
        [`${base}--${this.color}`]: !!this.color,
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
// global variables
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
// local variables
$sc-primary = {
  title: $primary-color,
  text: var(--main-card-color-notice),
}
$sc-error = {
  title: $error-color,
  text: $error-color,
}
$sc-colors = {
  primary: $sc-primary,
  error: $sc-error,
}
.start-card {
  padding: 1rem 0.25rem;

  +breakpoint(sm-and-up) {
    padding: 1rem 1rem;
  }
  +breakpoint(md-and-up) {
    padding: 1rem;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      padding-bottom: 100%;
      margin: 0;
    }
  }

  &__content {
    +breakpoint(md-and-up) {
      absolute-center();
      width: 100%;
    }
  }

  &__number {
    font-size: 42px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 40px;
    text-align: center;
    transition: color $anim-base-duration ease;
  }
  &__text {
    text-align: center;
    margin-top: 0.5rem;
    transition: color $anim-base-duration ease;

    +breakpoint(ms-and-down) {
      font-size: $font-size-medium;
    }

    +breakpoint(sm-and-up) {
      margin-top: 1rem;
    }

    &-link {
      text-decoration: none
      display: inline-block
    }
    &:after {
      display: block;
      content: "";
      height: 0.25rem;
      width: 0;
      background-color: $primary-color;
      transition: width 0.4s ease-in-out;
    }
    &:hover:after,
    &:focus:after {
      width: 100%;
    }
    &:hover{
      color:$primary-color;
    }
  }

  for $key, $color in $sc-colors {
    &--{$key} {
      & ^[0]__number {
        color: $color.title;
      }
      & ^[0]__text {
        color: $color.text;
      }
    }
  }
}
</style>
