import { BillMgrPeriods } from '@/models/BillMgr/periods';
import { parseCost } from '@/utils/parsers/costParser';

export default class SelectComponent {
  constructor({ value, options, readonly, required, imgBase, intname }) {
    this._imgBase = imgBase || '';
    this.value = value;
    // console.log(intname);
    this.intname = intname || '';
    this.options = this.getMappedOptions(options);
    this.disabled = readonly && readonly === 'yes';
    this.required = required && required === 'yes';
  }

  get internalValue() {
    // console.log('internalValue', this.options);
    return this.options.find(i => i.value === this.value);
  }

  set internalValue(obj) {
    this.value = obj.value;
  }

  get imgBase() {
    return this._imgBase;
  }
  set imgBase(str) {
    this._imgBase = str;
  }

  updateOptions(list) {
    this.options = this.getMappedOptions(list);
  }

  updateOptionsImages(base) {
    if (base) this.imgBase = base;
    this.options = this.options.map(opt => {
      const optFin = { ...opt };
      if (opt.image && !new RegExp(`^${this.imgBase}`).test(opt.image))
        optFin.image = this.imgBase + opt.image;
      return optFin;
    });
  }

  getMappedOptions(list) {
    // console.log(list);
    return list.map(i => {
      const props = {};
      if (this.intname) props.intname = this.intname;
      if (i.cost) props.cost = parseCost(i.cost) || 0;
      if (i.period) {
        props.period = i.period;
        props.periodKey = BillMgrPeriods[i.period ? i.period : 1];
      }
      if (this.intname === 'SupportLevel' && i.v.includes('Бесплатная')) props.base = true;
      if (i.image) props.image = this._imgBase + i.image;
      if (i.code) props.code = i.code;
      if (i.mask) {
        // console.log(i.mask);
        if (i.mask === '+9 (999) 999-99-99') props.mask = '+7 (999) 999-99-99';
        else props.mask = i.mask;
      }

      return {
        value: i.k,
        // intname: this.intname
        label: i.v ? i.v.replace('RUB', '₽') : '', // косяк прилетает в аренде физических серверов
        ...props,
      };
    });
  }
}
