import BillMgrApi from '@/services/api/BillMgrApi';
import router from '@/router/index';
import qs from 'qs';
import { IS_PROD, projectToUrlBM, urlProvider } from '@/layouts/LoginPage/mixins/currentProvider';
import store from '@/store';
// import { currentProvider, findAllByKey } from '@/layouts/LoginPage/mixins/currentProvider';
// function currentField() {
//   return findAllByKey(currentProvider, currentHost).toString();
// }
// function currentHost() {
//   return localStorage.getItem('host');
// }

const state = () => ({
  list: [],
  isLoading: false,
  current: null,
});

const getters = {
  current: state => {
    if (!state.current) return null;
    const current = state.list.find(item => item.id === state.current);
    return current || null;
  },
  provider: state => {
    if (!state.current) return null;
    return state.current;
  },
};

const mutations = {
  setList: (state, list) => (state.list = list),
  setIsLoading: (state, value) => (state.isLoading = value),
  setCurrent: (state, value) => {
    state.current = value;
  },
};
async function sendBillMgrAction(params) {
  return store.dispatch('sendBillMgrToolAction', params);
}
function isNeedTermOfUseAgreement(e) {
  return e === 'gdpr_termsofuse';
}
const actions = {
  fetchList({ commit }, payload = {}) {
    commit('setIsLoading', true);
    const params = {
      func: 'available_projects',
      show_metadata: 'on',
      newface: 'on',
      ...payload,
    };
    return BillMgrApi.get('', { params })
      .then(data => {
        if (
          data &&
          data.model &&
          data.model.available_projects &&
          data.model.available_projects.length
        ) {
          commit('setList', data.model.available_projects);
        }
      })
      .finally(() => commit('setIsLoading', false));
  },
  setProject({ dispatch, rootState }, payload = {}) {
    if (!payload.elid) throw new Error('Missed required param "elid"');
    localStorage.setItem('project', payload.elid);
    const params = {
      func: 'project.change',
      ...payload,
    };
    return dispatch('sendBillMgrToolAction', params, { root: true })
      .then(async data => {
        // console.log('dataSend', data, data.ok, data.ok.type);
        if (!(data.ok && data.ok.type === 'url' && data.ok.v)) {
        } else {
          const splitted = data.ok.v.split('?');
          dispatch('moduleApp/setHost', splitted[0], { root: true });
          rootState.moduleStart.navHeaderMenu = Object.assign({}, {});
          localStorage.setItem('redirectUrl', splitted[1]);
          // console.log(qs.parse(splitted[1]));
          await store
            .dispatch('authUser', qs.parse(splitted[1]), { root: true })
            .then(() => {
              // console.log('dataAuth', data, data.ok, data.ok.type);
              const token = localStorage.getItem('token');
              // console.log(payload);
              // времянка для смены проекта только для прода
              const test =
                `${urlProvider[localStorage.getItem('project')]}/#usercp/` + `auth=${token}`;
              // payload.elid === '5'
              //  ? // `${urlProvider[localStorage.getItem('project')]}/billmgr?` + `&auth=${token}`;
              // `${urlProvider[localStorage.getItem('project')]}/billmgr?` + `auth=${token}`
              // : `${urlProvider[localStorage.getItem('project')]}/#usercp/` + `auth=${token}`;
              // console.log(test);
              // console.log('---------token----------', test);
              localStorage.setItem('auth', test);
            })
            .catch(data => {
              // console.log('dataProvider', data, data.e, data.auth);
              if (isNeedTermOfUseAgreement(data.e)) {
                sendBillMgrAction({
                  func: data.e.type,
                  auth: data.auth,
                  field_2: 'on',
                  field_3: 'on',
                  field_5: 'on',
                }).then(data => console.log(data));
              } else {
                console.log(data);
                // setError(data);
              }
            });
          // console.log('-----');
          if (!IS_PROD) await dispatch('moduleStart/fetchAppInfo', {}, { root: true });
          IS_PROD
            ? window.open(localStorage.getItem('auth'), '_self')
            : await router.push({ name: 'start' }).catch(() => {});
        }
        if (data.ok && data.ok.type === 'form' && data.ok.v) {
          const log = qs.parse(data.ok.v);
          const project = localStorage.getItem('project');
          await sendBillMgrAction(log)
            .then(data => {
              localStorage.setItem('token', data.auth);
              dispatch('moduleApp/setHost', projectToUrlBM[project], { root: true });
              if (data.ok && data.ok.type === 'top') {
                sendBillMgrAction({
                  func: data.func,
                  auth: data.auth,
                }).then(data => {
                  localStorage.setItem('token', data.auth);
                });
                // .then(() => {
                //   router.push({ name: 'start' }).catch(() => {});
                // });
              }
            })
            .then(() => router.push({ name: 'start' }).catch(() => {}));
          router.push({ name: 'start' }).catch(() => {});
        }
      })
      .catch(data => {
        if (isNeedTermOfUseAgreement(data.e)) {
          sendBillMgrAction({
            func: data.e.type,
            auth: data.auth,
            field_2: 'on',
            field_3: 'on',
            field_5: 'on',
          }).then(() => console.log('/'));
        } else {
          console.log(data);
          // setError(data);
        }
      });
  },
  reset({ commit }) {
    commit('setIsLoading', false);
    commit('setCurrent', null);
    commit('setList', []);
    localStorage.removeItem('project');
  },
};
// export function setError(data) {
//   const e = data.e ? data.e : data;
//   this.error = e.msg;
//   if (e.object === 'badpassword') {
//     this.fields.forEach(i => {
//       if (i.name === 'username' || i.name === 'password') i.invalid = true;
//     });
//   }
// }

const moduleProviders = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
export default moduleProviders;
