import container from './container.vue';

export const RouteAntiDdos = {
  path: '/antiddosgcore',
  name: 'antiddos',
  redirect: { name: 'antiDdosMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Защита AntiDDoS',
    navigation: {
      service: {
        key: 'antiddosgcore',
        icon: 'antiddosgcore',
        order: 11,
      },
    },
    access: ['mainmenuservice.antiddosgcore'],
  },
  children: [
    {
      path: '',
      name: 'antiDdosMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Защита AntiDDoS',
      },
      component: () => import(/* webpackChunkName: "antiddos-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'antiDdosOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Заказ защиты AntiDDoS',
      },
      component: () =>
        import(/* webpackChunkName: "antiddos-order" */ './pages/Order/AntiDdosOrder.vue'),
      children: [
        {
          path: '/antiddosgcore/order',
          redirect: { name: 'antiDdosOrder' },
        },
        {
          path: '/antiddosgcore/order/:id/:period',
          name: 'antiDdosOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "antiddos-order-details" */ './pages/Order/AntiDdosOrderDetails.vue'
            ),
        },
        {
          path: '/antiddosgcore/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'antiDdosOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'antiDdosDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "antiddos-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewAntiDdos' },
      props: true,
      children: [
        {
          path: '',
          meta: {
            name: 'Обзор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "antiddos-info-view" */ './pages/Information/views/View.vue'
            ),
          name: 'viewAntiDdos',
        },
        {
          path: 'config',
          meta: {
            name: 'Конфигуратор',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "antiddos-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configAntiDdos',
        },
        {
          path: 'history',
          meta: {
            name: 'История',
            isShow: true,
            isLink: false,
          },
          component: () =>
            import(
              /* webpackChunkName: "antiddos-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyAntiDdos',
        },
      ],
    },
  ],
};
