import container from './container.vue';

export const RouteColo = {
  path: '/colo',
  name: 'colo',
  redirect: { name: 'coloMain' },
  component: container,
  meta: {
    isShow: true,
    isLink: true,
    name: 'Размещение физического сервера',
    navigation: {
      service: {
        key: 'colo',
        icon: 'rps',
        order: 12,
      },
    },
    access: ['mainmenuservice.colocation'],
  },
  children: [
    {
      path: '',
      name: 'coloMain',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Размещение физического сервера',
      },
      component: () => import(/* webpackChunkName: "colo-main" */ './pages/Main/Main.vue'),
    },
    {
      path: 'order/plans',
      name: 'coloOrder',
      meta: {
        isShow: true,
        isLink: false,
        name: 'Заказ на размещение физического сервера',
      },
      component: () => import(/* webpackChunkName: "colo-order" */ './pages/Order/ColoOrder.vue'),
      children: [
        {
          path: '/service/colo/order',
          redirect: { name: 'coloOrder' },
        },
        {
          path: '/service/colo/order/:id/:period',
          name: 'coloOrderDetails',
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "colo-order-details" */ './pages/Order/ColoOrderDetails.vue'
            ),
        },
        {
          path: '/service/colo/order/:id/:period/3', // костыль для project=3  - нужен во всем проекте
          redirect: { name: 'coloOrderDetails' },
        },
      ],
    },
    {
      path: ':id',
      name: 'coloDetail',
      meta: {
        isShow: true,
        isLink: false,
      },
      component: () =>
        import(/* webpackChunkName: "colo-info" */ './pages/Information/container.vue'),
      redirect: { name: 'viewColo' },
      props: true,
      children: [
        {
          path: '',
          component: () =>
            import(/* webpackChunkName: "colo-info-view" */ './pages/Information/views/View.vue'),
          name: 'viewColo',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Обзор',
          },
        },
        {
          path: 'config',
          component: () =>
            import(
              /* webpackChunkName: "colo-info-config" */ './pages/Information/views/Config.vue'
            ),
          name: 'configColo',
          meta: {
            isShow: true,
            isLink: false,
            name: 'Конфигуратор',
          },
        },
        {
          path: 'history',
          component: () =>
            import(
              /* webpackChunkName: "colo-info-history" */ './pages/Information/views/History.vue'
            ),
          name: 'historyColo',
          meta: {
            isShow: true,
            isLink: false,
            name: 'История',
          },
        },
      ],
    },
  ],
};
