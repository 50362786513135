import BaseConfigurator from '../base/BaseConfigurator';
import { isEmpty } from 'lodash';
import QualityConfigurator from '@/models/base/QualityConfigurator';

export class QualityTariff extends BaseConfigurator {
  constructor(args) {
    super(args);
    this._init(args);
  }

  _init(args) {
    this.call_me = args.call_me;
    this.comment = args.comment;
    this.branding = args.branding;
    this.links = args.links;
    this.saved_filters = args.saved_filters;
    this.slist_user_emails = args.slist_user_emails;
    this.slist_user_items = args.slist_user_items;
    this.slist_user_phones = args.slist_user_phones;
    this.slist_user_tickets = args.slist_user_tickets;
  }

  update(args) {
    for (let key in args) {
      this[key] = args[key];
    }
  }

  set fields(args) {
    this._fields = {
      ...this._fields,
      ...args,
    };
  }

  get fields() {
    return this._fields;
  }

  set slist(args) {
    this._slist = {
      ...this._slist,
      ...args,
    };
  }
  get slist() {
    return this._slist;
  }

  makeConfigurator(data, formData = {}, configData = {}, payload) {
    let customfields = data.customfields || data._fields || {};
    if (!isEmpty(formData)) {
      Object.keys(formData).forEach(i => {
        if (customfields[i] && customfields[i].type && customfields[i].type === 'text')
          customfields[i].type = 'textarea';
        if (customfields[i]) customfields[i].value = formData[i];
      });
    }
    customfields?.slist_user_tickets?.value
      ? (customfields.slist_user_tickets.value = payload.ticket)
      : null;
    customfields?.slist_user_items?.value
      ? (customfields.slist_user_items.value = payload.theme)
      : null;
    customfields?.slist_user_tickets?.hidden
      ? (customfields.slist_user_tickets.hidden = true)
      : null;
    if (customfields?.slist_user_emails?.readonly) customfields.slist_user_emails.readonly = 'yes';
    if (customfields?.slist_user_emails?.hidden) customfields.slist_user_emails.hidden = true;
    customfields?.slist_user_items?.readonly
      ? (customfields.slist_user_items.readonly = 'yes')
      : null;
    customfields?.slist_user_items?.hidden ? (customfields.slist_user_items.hidden = true) : null;
    const model = data.quality || data.qualityLk || {};
    const slist = data._slist || {};
    model?.slist_user_tickets && payload?.ticket
      ? (model.slist_user_tickets = payload.ticket)
      : null;
    try {
      const config = new QualityConfigurator({
        customfields,
        model,
        slist,
      });

      return config;
    } catch (e) {
      return e;
    }
  }

  set info({ slist, model, required }) {
    this._info = {
      slist,
      required,
      model,
    };
  }

  get info() {
    return this._info;
  }
}
