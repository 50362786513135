import { SoftTools } from '@/models/BillMgr/Tools';
import Vue from 'vue';
import { SoftTariff } from '@/models/BillMgr/SoftTariff';
const sortOrders = ['asc', 'desc'];
const vpsModes = ['grid', 'table'];

export const MUTATIONS = {
  SET_LIST: (state, list) => {
    try {
      state.list = list.map(i => new SoftTariff(i));
    } catch (e) {
      console.error(e);
    }
  },
  UPDATE_LIST: (state, list) => {
    const compareList = list.map(i => ({ id: i.id, isset: false, data: { ...i } }));
    const filteredOldList = state.list.reduce((acc, item) => {
      const compare = compareList.find(i => i.id == item.id);
      if (!!compare) {
        item.update(compare.data);
        acc.push(item);
        compare.isset = true;
      }
      return acc;
    }, []);
    const newList = compareList.filter(i => !i.isset).map(i => new SoftTariff(i.data));
    state.list = [...filteredOldList, ...newList];
  },
  SET_CURRENT: (state, payload) => {
    state.current = payload;
  },
  setSoftPanel: (state, value) => {
    state.list.filter(x => x.id === value)[0].isSoft = true;
  },
  SET_OTHER_INFO_ABOUT_TARIFF: (state, { prop, value, id }) => {
    const innerId = id || state.current;
    const tariff = state.list.find(i => i.id === innerId);
    if (tariff && tariff instanceof SoftTariff) {
      Vue.set(tariff, prop, value);
    }
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value;
  },
  SET_SORT: (state, payload) => {
    if (payload.prop && sortOrders.includes(payload.order)) {
      state.sort = payload;
    }
  },
  SET_MODE: (state, value) => {
    if (vpsModes.includes(value)) state.mode = value;
  },
  SET_TOOLS: (state, value) => {
    // console.log(state.tools?.prolong);
    // console.log(value);
    // if (!state.tools?.prolong?.func)
    try {
      state.tools = new SoftTools(value);
      // console.log(state.tools);
      // state.tools.prolong = {
      //   func =
      //     state.tools?.prolong?.func === 'soft.prolong.redirect'
      //       ? 'service.prolong'
      //       : 'service.prolong';
      // }

      state.tools.prolong.func =
        state.tools?.prolong?.func === 'soft.prolong.redirect'
          ? 'service.prolong'
          : 'service.prolong';
      // console.log(state.tools.prolong.func);
    } catch {
      state.tools.prolong = {
        func: 'service.prolong',
      };
    }
    // else
    //   state.tools.prolong = {
    //     func: 'service.prolong',
    //   };
  },
};
