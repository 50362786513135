import container from './container.vue';

export const RouteStartLayout = {
  name: 'start',
  path: '/start',
  component: container,
  meta: {
    isShow: true,
    isLink: false,
    name: 'Главная',
    navigation: {
      service: {
        key: 'home',
        icon: 'home-icon',
        order: 1,
      },
    },
  },
};
