import { VhostTariff } from '@/models/BillMgr/VhostTariff';
import { QualityTariff } from '@/models/BillMgr/QualityTariff';
import { AntiDdosTariff } from '@/models/BillMgr/AntiDdosTariff';
import Vue from 'vue';

export const MUTATIONS = {
  SET_LIST: (state, list) => {
    try {
      state.list = list;
    } catch (e) {
      console.error(e);
    }
  },
  SET_ALL_TICKETS: (state, allTickets) => {
    try {
      state.allTickets = allTickets;
    } catch (e) {
      console.error(e);
    }
  },
  UPDATE_INFO: (state, { prop, value }) => {
    if (prop && value != undefined) {
      Vue.set(state.quality, prop, value);
    }
  },
  UPDATE_INFO_LK: (state, { prop, value }) => {
    if (prop && value != undefined) {
      Vue.set(state.qualityLk, prop, value);
    }
  },
  SET_OTHER_INFO_ABOUT_TARIFF: (state, { prop, value }) => {
    const tariff = state.quality;
    console.log(tariff, prop, value);
    // if (tariff && tariff instanceof AntiDdosTariff) {
    Vue.set(tariff, prop, value);
    // }
  },
  SET_QUALITY: (state, quality) => {
    try {
      state.quality = new QualityTariff(quality);
    } catch (e) {
      console.error(e);
    }
  },
  SET_QUALITY_LK: (state, quality) => {
    try {
      state.qualityLk = new QualityTariff(quality);
    } catch (e) {
      console.error(e);
    }
  },
  SET_ALL_INCIDENT: (state, allIncident) => {
    try {
      state.allIncident = allIncident;
    } catch (e) {
      console.error(e);
    }
  },
  SET_NEW_TICKETS: (state, newTickets) => {
    try {
      state.newTickets = newTickets;
    } catch (e) {
      console.error(e);
    }
  },
  GET_ALL_TICKETS_ARHIVE: (state, allTicketsArhive) => {
    try {
      state.allTicketsArhive = allTicketsArhive;
    } catch (e) {
      console.error(e);
    }
  },
  GET_ALL_NOTIFICATIONS: (state, AllNotifications) => {
    try {
      state.AllNotifications = AllNotifications;
    } catch (e) {
      console.error(e);
    }
  },
  SET_CURRENT_TICKET: (state, currentTicket) => {
    try {
      currentTicket[0].type === 'info'
        ? (state.currentTicket = currentTicket.slice(1))
        : (state.currentTicket = currentTicket);
    } catch (e) {
      // console.error(e);
    }
  },
  SET_CURRENT_TICKET_THEME: (state, currentTicketTheme) => {
    try {
      state.currentTicketTheme = currentTicketTheme;
    } catch (e) {
      console.error(e);
    }
  },
  SET_CURRENT_TICKET_ID: (state, currentTicketID) => {
    try {
      state.currentTicketID = currentTicketID;
    } catch (e) {
      console.error(e);
    }
  },
  SET_CURRENT_STATUS: (state, currentStatus) => {
    try {
      state.currentStatus = currentStatus;
    } catch (e) {
      console.error(e);
    }
  },
  SET_FILES: (state, files) => {
    try {
      state.files.push(files);
    } catch (e) {
      console.error(e);
    }
  },
  DELETE_FILES: (state, key) => {
    try {
      state.files.splice(key, 1);
    } catch (e) {
      console.error(e);
    }
  },
  RESET_FILES: state => {
    state.files.length = 0;
  },
  SET_SERVICE_LIST: (state, serviceList) => {
    try {
      state.serviceList = serviceList;
    } catch (e) {
      console.error(e);
    }
  },
  SET_DEPARTMENT: (state, department) => {
    try {
      state.department = department;
    } catch (e) {
      console.error(e);
    }
  },
  SET_CURRENT_NOTIFICATION: (state, notification) => {
    try {
      state.currentNotification = notification;
    } catch (e) {
      console.error(e);
    }
  },
  SET_IS_LOADING: (state, value) => {
    state.isLoading = value;
  },
  toArhive: (state, value) => {
    state.toArhive = value;
  },
};
